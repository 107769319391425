<template>
  <div>
    <block-settings></block-settings>
    
    <process-settings></process-settings>
  </div>
</template>

<script>
import BlockSettings from './BlockSettings.vue'
import ProcessSettings from './ProcessSettings.vue'

export default {
  components: {

    BlockSettings,
    ProcessSettings,

  },
  setup() {
  },
}
</script>

<style lang="scss">
</style>
