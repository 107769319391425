<template>
  <b-modal
    id="currency-list-modal"
    ref="currencyListModal"
    centered
    no-close-on-backdrop
    title="Tipo Cambio"
    hide-footer
    @show="getData"
    hide-header-close
  >
    <div
      v-show="isLoading"
      class="my-3"
    >
      <custom-loading></custom-loading>
    </div>
    <div
      v-show="!isLoading"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          id="currency-list-modal-form"
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <!-- Euro -->
          <validation-provider
            #default="validationContext"
            name="Euro"
            rules="required|regex:^[0-9]\d*(.\d+)?$"
          >
            <b-form-group
              label="Euro"
              label-for="euro"
            >
              <b-form-input
                id="euro"
                v-model="euroValue"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2 justify-content-center">
            <b-button
              id="currency-list-modal-submit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <b-spinner
                v-if="isLoadingButton"
                small
              />
              Guardar
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="onCancel"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
  BInputGroup, BInputGroupAppend, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, getCurrentInstance, inject } from 'vue'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,

    CustomLoading,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup() {
    const isLoadingButton = ref(false)
    const isLoading = ref(true)
    const euroValue = ref(0)

    const vm = getCurrentInstance().proxy

    // method
    const getData = async () => {
      try {
        isLoading.value = true
        
        const result = await axios
        .post(`${process.env.VUE_APP_BASE_URL}/get_currency`,{
        })
        euroValue.value = result.data[0].valor
      } catch(e) {
        console.log(e)
      } finally {
        isLoading.value = false
      }
    }

    const onSubmit = async () => {
      const btnModal = document.getElementById('currency-list-modal-submit')

      try {
        isLoadingButton.value = true
        
        btnModal.setAttribute('disabled', true)

        await axios
        .post(`${process.env.VUE_APP_BASE_URL}/set_currency`,{
          data: [
            {
              "moneda": "euro",
              "valor": parseFloat(euroValue.value)
            }
          ]
        })

        vm.$swal({
          title: 'Datos Registrados!',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
          willClose: ()=> {
            getData()
            vm.$bvModal.hide('currency-list-modal')
          }
        })
      } catch(e) {
        vm.$swal({
          title: 'Error al registrar nuevas filas!',
          text: `Intente de nuevo`,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } finally {
        isLoadingButton.value = false
        btnModal.removeAttribute('disabled')
      }
    }

    const onCancel = () => {
      vm.$bvModal.hide('currency-list-modal')
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(euroValue)

    return {
      getData,
      onSubmit,
      onCancel,

      euroValue,
      isLoading,
      isLoadingButton,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">

</style>
